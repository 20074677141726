import React from "react";
import { Card } from "react-bootstrap";
import { SPEEDSIZE_PREFIX } from "../../App";
const urlPrefix = "https://m.codere.com.co/deportesCol/#/MercadosPage";
const betSlipPrefix = "https://m.codere.com.co/deportesCol/#/HomePage";
function replaceSpanishCharacters(text) {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export default function SegmentedCard({ game }) {
  const imgPrefix = `${SPEEDSIZE_PREFIX}https://www.codere.com.co/assets/shirts`;

  function urlHandler(flag, NodeId) {
    // flag: 0 for game event || 1 for betslip)}
    if (!flag) {
      return `${urlPrefix}?NodeId=${
        game.NodeId
      }&LeagueName=${replaceSpanishCharacters(
        game.LeagueName
      )}&Name=${replaceSpanishCharacters(game.Name)}&Sporthandle=${
        game.SportHandle
      }&SportName=${replaceSpanishCharacters(
        game.SportName
      )}&StartDate=/Date(${new Date(game.StartDate).getTime()})/`;
    } else return `${betSlipPrefix}?addbet=${NodeId}`;
  }

  // console.log(game);

  const dateHandler = (date) => {
    const tempDate = new Date(date);
    const options = {
      day: "numeric",
      month: "long",
      hour: "2-digit",
      minute: "2-digit",
    };

    const time = tempDate.toLocaleString("es-sp", options).split(",")[1];

    if (tempDate.getDate() === new Date().getDate()) return `hoy ${time}`;
    else if (tempDate.getDate() === new Date().getDate() + 1)
      return `mañana ${time}`;
    // else return "boy";
    return tempDate.toLocaleString("es-sp", options);
  };

  function fixTeamName(str) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\x00-\x7F]/g, "")
      .replaceAll(" ", "-")
      .replaceAll(".", "")
      .replaceAll("&-", "");
  }

  function shorthenTeamName(str) {
    switch (str) {
      case "Atlético Bucaramanga":
        str = "Atl. Bucaramanga";
        break;
      case "Independiente Medellin":
        str = "Ind. Medellin";
        break;
    }
    return str;
  }
  return (
    <Card key={game.Name} className="my-card text-white p-1">
      <div className="d-flex justify-content-between pad-l-r w-100 fs-sm">
        {/* <div className="fw-bold elepsis-50">{game.LeagueName}</div> */}
        <div className="text-primary w-100 text-white d-flex justify-content-center text-capitalize">
          {dateHandler(game.StartDate)}
        </div>
      </div>
      <div className="names d-flex justify-content-between">
        {game.Participants.map((participant, k) => (
          <div
            key={k}
            className={`w-50 m-t-b-1 d-flex justify-content-between pad-l-r align-items-center ${
              k ? "flex-row-reverse" : ""
            }`}
          >
            <div
              className={`eleps fs-sm text-${
                !k ? "start" : "end"
              } justify-content-${!k ? "start" : "end"}`}
            >
              {participant.LocalizedNames.LocalizedValues[0].Value}
            </div>
            <div>
              {/* <img
                width="25px"
                height="25px"
                src={`${imgPrefix}/${fixTeamName(
                  participant.LocalizedNames.LocalizedValues[0].Value
                )}.png`}
              /> */}
            </div>
          </div>
        ))}
      </div>
      <div className="card-games d-flex justify-content-evenly w-100">
        {game.Games[0].Results.map((result, k) => (
          <div
            key={k}
            className="odds p-1 m-1 rounded-2 d-flex flex-column"
            onClick={() => window.open(urlHandler(1, result.NodeId), "_blank")}
          >
            <div>{k === 1 ? "X" : k === 2 ? k : k + 1}</div>
            <div>{parseFloat(result.Odd).toFixed(2).replaceAll(".", ",")}</div>
          </div>
        ))}
      </div>
    </Card>
  );
}
