import { Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CodereHelmet, SPEEDSIZE_PREFIX } from "../../../../App";
import { useEffect } from "react";
import TableOfContents from "../../TableOfContents";
import { floating } from "../../helpers";
const ApuestasCiclismo = (props) => {
  const prefix = `${SPEEDSIZE_PREFIX}https://www.codere.com.co/Colombia/images/seo-sport/cuotas-deportivas`;

  useEffect(() => {
    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Cuándo y dónde será la copa del mundo de 2026?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "FIFA anunció las 16 sedes de la Copa Mundial de 2026. Y por supuesto las ciudades que recibirán al evento más importante del deporte. Tres serán en México, dos en Canadá y las once restantes en Estados Unidos.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuántos países clasifican al mundial?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "En la reciente edición del Mundial en total clasificaron 32 Selecciones. Sin embargo, para el Mundial 2026, que se realizará en Estados Unidos, Canadá y México, por primera vez, tendrá un total de 48 selecciones. De esta forma, la Copa Mundial 2026 tendrá 16 ciudades anfitrionas de la primera edición del torneo más emblemático del balompié mundial en enfrentar a 48 equipos.",
          },
        },
        {
          "@type": "Question",
          name: "¿Qué país ha organizado más Mundiales en la historia?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Brasil sigue liderando la lista, no solo es el país que más Mundiales ha ganado, con un total de cinco, sino que también ha sido anfitrión del torneo en dos ocasiones. Ningún país ha albergado más de dos torneos, y los cinco grandes continentes han organizado al menos un Mundial.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuándo inician los procesos de clasificación al Mundial de 2026?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Las Eliminatorias para el Mundial de Canadá, Estados Unidos y México 2026 comenzarán en este 2023, el fixture de las próximas eliminatorias todavía no está definido, se sorteará en las primeras semanas del año, y el torneo comenzará en marzo de 2023 con una doble fecha y terminará en octubre de 2025.",
          },
        },
      ],
    };

    let title = "Guía Apuestas de Ciclismo » Bono de $200.000  | Codere®";
    let desc =
      "En esta guía encontrarás las opciones que tienes para hacer un buen juego con el emocionante deporte de las bielas 🚴. ¡Sigue leyendo para enterarte!";

    let can = "https://www.codere.com.co/cuotas-deportivas/apuestas-ciclismo";

    CodereHelmet(title, desc, can, [json]);
  }, []);

  const table_of_contents_list = [
    {
      title: "Competencias ciclísticas ",
      id: "anchor-1",
    },
    {
      title: "Competidores de ciclismo famosos",
      id: "anchor-2",
    },
    {
      title: "Tipos de apuestas",
      id: "anchor-3",
    },
    {
      title: "Consejos",
      id: "anchor-4",
    },
    {
      title: "Preguntas frecuentes",
      id: "anchor-5",
    },
  ];

  return (
    <>
      {floating("Pedalea hacia la victoria", false)}
      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${prefix}/${
            props.flag ? "M" : "D"
          }-ciclismo-Header.webp)`,
          backgroundSize: "cover",
        }}
      />
      <Container className="seo">
        <h1 className="text-center codere-green fw-normal mt-4">
          Apuestas en Ciclismo: disfruta el recorrido apostando con Codere
        </h1>
        <p>Si te interesa jugar con el ciclismo, estás en el lugar correcto.</p>
        <p>
          El ciclismo es uno de los deportes más importantes en Colombia, por
          eso, aquí te explicaremos las opciones que tienes para hacer un buen
          juego con el emocionante deporte de las bielas. ¡Sigue leyendo para
          enterarte!
        </p>
        <TableOfContents table={table_of_contents_list} />

        <h2 className="codere-green mt-4">
          Competencias más importantes del ciclismo
        </h2>
        <p>
          Dentro de las competencias más importantes de este deporte podremos
          encontrar el Campeonato Mundial de Ciclismo en Ruta, que reúne a los
          mejores ciclistas del mundo representando a su país de origen; el Tour
          de Francia, Giro de Italia, y La Vuelta a España, estas tres últimas
          son consideradas como las más grandes de este deporte, ya que cada una
          tiene etapas con terrenos de montaña o circuitos de sprint.
        </p>
        <h2 id={"anchor-2"} className="codere-green">
          Competidores de ciclismo famosos
        </h2>
        <p>
          Dentro del ranking de los mejores en el ciclismo mundial hay varios
          nombres destacados: por Latinoamérica figuran los ciclistas
          ecuatorianos Richard Carapaz y Jhonatan Caicedo, así como también los
          colombianos Santiago Buitrago, Egan Bernal, Sergio Higuita, Daniel
          Martínez y Juan Sebastián Molano, entre otros.
        </p>
        <p>
          En Europa se destacan el español Enric Maas, el neerlandés Mathieu Van
          del Poel, los eslovenos Primož Roglič y Tadej Pogačar, y los belgas
          Wout van Aert y Remco Evenepoel.
        </p>
        <h2 id="anchor-3" className="codere-green">
          Tipos de apuestas online
        </h2>
        <p>
          Dentro de la sección “ciclismo”, las{" "}
          <a href="https://www.codere.com.co">apuestas</a> más comunes son para
          el ganador final de la general, el ganador por etapa y las cuotas
          anticipadas. Estas últimas son ideales para aquellos que analizan a
          los corredores que compiten en las grandes competiciones del deporte
          de las bielas.
        </p>
        <h3 className="codere-green">Ganador final</h3>
        <p>
          La apuesta "Ganador final" en ciclismo se refiere a una forma de
          apostar en la que seleccionas al ciclista que crees que ganará una
          carrera de ciclismo en particular, antes de que toda la carrera
          comience. Las cuotas para esta apuesta pueden variar según la
          reputación y el historial del ciclista, así como las condiciones de la
          carrera y otros factores.
        </p>
        <h3 className="codere-green">Ganador por Etapa</h3>
        <p>
          La apuesta "Ganador por etapa" es una forma de apostar donde
          seleccionas al ciclista que crees que ganará una etapa específica de
          una carrera. Las cuotas para esta apuesta pueden variar según la
          dificultad y las condiciones de la etapa, así como la reputación y el
          historial de los ciclistas participantes. Si el ciclista que
          seleccionaste gana la etapa en la que apostaste, tú ganarás la apuesta
          y recibirás un premio en función de las{" "}
          <a href="https://www.codere.com.co/cuotas-deportivas">cuotas</a>{" "}
          establecidas por Codere.
        </p>
        <h3 className="codere-green">Anticipadas</h3>

        <p>
          La apuesta "Anticipada" en ciclismo es una forma de realizar una
          apuesta antes de que una carrera específica de ciclismo o un evento
          deportivo relacionado con el ciclismo se lleve a cabo. Estas son muy
          populares entre los entusiastas de este deporte y pueden ofrecer
          oportunidades para obtener ganancias significativas si se realiza una
          selección precisa.
        </p>
        <h2 id="anchor-4" className="codere-green">
          Consejos para apuestas de ciclismo
        </h2>
        <p>
          Debes Investigar a los corredores, estudiar la carrera y sus
          recorridos, ten en cuenta las tácticas del equipo, observa las cuotas
          y las probabilidades; de igual forma deberás establecer un presupuesto
          específico para estas apuestas y es importante que no te dejes llevar
          por la emoción, jugar en vivo con precaución, seguir las noticias,
          considerar el historial de la carrera, y por supuesto pronosticar de
          forma responsable para que te diviertas con Codere.
        </p>
        <h2 id="anchor-5" className="codere-green">
          Preguntas Frecuentes
        </h2>

        <h3 className="codere-green">¿Qué se hace en el ciclismo?</h3>
        <p>
          Este deporte implica montar en bicicleta en diversas modalidades y
          disciplinas. Aquí te contamos algunas de las actividades y prácticas
          más comunes relacionadas con el ciclismo: ciclismo de carretera,
          ciclismo de montaña, ciclismo de pista, BMX (bicicross), ciclo-cross,
          ciclismo en pista de velocidad, ciclismo de contrarreloj, entre otros.
        </p>
        <h3 className="codere-green">¿Cuándo es la vuelta a España?</h3>
        <p>
          También conocida como “La Vuelta”, es una de las tres Grandes Vueltas
          del ciclismo. Esta se celebra anualmente en España juntando otros
          territorios europeos cercanos y generalmente tiene lugar entre los
          meses de agosto y septiembre.
        </p>

        <h3 className="codere-green">
          ¿Cómo puedo apostar en el mundial de ciclismo?
        </h3>
        <p>
          Debes verificar las cuotas, las opciones de apuesta e investigar a los
          corredores. También puedes establecer un presupuesto específico para
          tus apuestas en cualquier competencia, seguir las actualizaciones,
          disfrutar de la carrera y jugar responsablemente con Codere. Mira
          nuestra oferta en Codere, vive la emoción del ciclismo y la mejor
          experiencia de tus pronósticos deportivos.
        </p>

        <LazyLoadImage
          className="lazyloadimg"
          src={`${prefix}/${props.flag ? "M" : "D"}-Cycling-3.webp`}
        />
      </Container>
    </>
  );
};

export default ApuestasCiclismo;
