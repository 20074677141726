import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade } from "swiper";
import SlideButton from "./Slide-Button";
import { Button, Image, Modal, NavLink } from "react-bootstrap";
import axios from "axios";

import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";

import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import myStore from "../../mobX/Store";
import { Arrowrightw } from "../../icons";

const MySwiper = observer(({ segmented_banners }) => {
  const [swiper, setSwiper] = useState(null);
  const location = window.location.host;

  const [imgs, setImgs] = useState([]);
  const [liveBanners, setLiveBanners] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentTycType, setCurrentTycType] = useState(null);
  const [currentBannerName, setCurrentBannerName] = useState("");
  const [showTycModal, setShowTycModal] = useState(false);

  const [ctaText, setCtaText] = useState("Regístrate");
  const [ctaLink, setCtaLink] = useState(myStore.regis);

  const indexHandler = (swiper) => {
    if (myStore.segmented) {
      setCtaText("Acceder");
      setCtaLink(myStore.acceder);
      return;
    }
    setCtaText(
      liveBanners[swiper.realIndex].ctaText
        ? liveBanners[swiper.realIndex].ctaText
        : "Regístrate"
    );
    setCtaLink(
      liveBanners[swiper.realIndex].ctaURL
        ? liveBanners[swiper.realIndex].ctaURL
        : "https://m.codere.com.co/deportesCol/#/mso/RegistroCONewPage"
    );
    setCurrentTycType(
      liveBanners[swiper.realIndex].tycType
        ? liveBanners[swiper.realIndex].tycType
        : ""
    );
    setCurrentBannerName(
      liveBanners[swiper.realIndex].tycTitle
        ? liveBanners[swiper.realIndex].tycTitle
        : ""
    );
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/prd/colombia`)
      .then((res) => {
        let x = res.data[0].slide_list.filter(BannerFilter);
        setImgs([...x]);

        setLiveBanners(
          x.reduce((acc, obj) => {
            const tempBanner = {
              ...obj,
              image: obj.image.replace(
                "xxx",
                myStore.flag ? "mobile" : "desktop"
              ),
            };
            acc.push(tempBanner);
            return acc;
          }, [])
        );

        setCtaText(x[0].ctaText);
        setCtaLink(x[0].ctaLink);
        if (x[0].tycType) {
          setCurrentBannerName(x[0].tycTitle);
          setCurrentTycType(x[0].tycType);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (liveBanners.length) {
      let tempList = imgs.reduce((acc, obj) => {
        const tempBanner = {
          ...obj,
          image: obj.image.replace("xxx", myStore.flag ? "mobile" : "desktop"),
        };
        acc.push(tempBanner);
        return acc;
      }, []);

      setLiveBanners([...tempList]);
    }
  }, [myStore.flag]);

  useEffect(() => {
    if (swiper) {
      if (showTycModal) swiper.autoplay.pause();
    }
  }, [showTycModal]);

  function dateInBetween(banner) {
    return (
      new Date() >= new Date(banner.startDate) &&
      new Date() <= new Date(banner.endDate)
    );
  }

  function BannerFilter(banner) {
    if (!banner.scheduled) return banner;
    else {
      if (dateInBetween(banner)) return banner;
    }
  }

  const modalGenerator = () => {
    return (
      <>
        <Modal
          centered
          show={showTycModal}
          onHide={() => setShowTycModal(false)}
          contentClassName="terms-tyc"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="test">
                <h5>Términos y condiciones</h5>
                <h5>{currentBannerName}</h5>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentTycType && tycGenerator(currentTycType)}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setShowTycModal(false)}
              className="accept-btn"
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const tycGenerator = (type) => {
    return (
      <>
        <iframe
          width="100%"
          height="400px"
          title="tyc"
          src={
            location.includes("codere.com")
              ? `https://www.codere.com.co/assets/tyc/col-tyc-${type}.html` // for prd env
              : `https://blog.codere.com.co/terminos-y-condiciones/${type}` // for testing env
          }
        />
      </>
    );
  };

  return (
    <div id="carousel-section">
      {liveBanners.length > 0 && (
        <>
          <Swiper
            onSwiper={(swiper) => setSwiper(swiper)}
            onSlideChange={(swiper) => indexHandler(swiper)}
            modules={[Pagination, EffectFade, Autoplay]}
            pagination={{ clickable: true }}
            effect={"fade"}
            // lazy={{ loadPrevNext: true, loadPrevNextAmount: 1 }}
            autoplay={{ delay: 3250, disableOnInteraction: false }}
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
          >
            {liveBanners.map((item, index) => (
              <SwiperSlide key={item.banner_name}>
                <NavLink href={ctaLink}>
                  <Image
                    src={item.image}
                    alt={item.banner_name}
                    width={myStore.flag ? "300" : "400"}
                    height={myStore.flag ? "410" : "140"}
                  />
                </NavLink>
              </SwiperSlide>
            ))}
          </Swiper>
          {!myStore.flag ? (
            <div id="des-reg">
              <Button
                className={`central-regis c gl-effect ${
                  myStore.segmented ? "segmentedReactSwipeButton" : ""
                }`}
                href={ctaLink}
                rel={"nofollow"}
              >
                {ctaText}
                <Arrowrightw />
              </Button>
              {currentTycType && (
                <span className="tyc" onClick={() => setShowTycModal(true)}>
                  Términos y condiciones
                </span>
              )}
            </div>
          ) : (
            <>
              {currentTycType && (
                <div className="mob-tyc">
                  <span className="tyc" onClick={() => setShowTycModal(true)}>
                    Términos y condiciones
                  </span>
                </div>
              )}
            </>
          )}
          {myStore.flag && (
            <SlideButton
              regis={ctaLink}
              ctaText={myStore.segmented ? "Acceder" : ctaText}
              segmented={myStore.segmented}
            />
          )}
          {modalGenerator()}
        </>
      )}
    </div>
  );
});
export default MySwiper;
