import { Button, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CodereHelmet } from "../../../../App";
import { useEffect } from "react";
import TableOfContents from "../../TableOfContents";
import { floating } from "../../helpers";
import { SPEEDSIZE_PREFIX } from "../../../../App";
const ApuestasBaloncesto = (props) => {
  const prefix =
    `${SPEEDSIZE_PREFIX}https://www.codere.com.co/Colombia/images/seo-sport/cuotas-deportivas`;

  useEffect(() => {
    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Quién ha ganado más mundiales de baloncesto?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Estados Unidos es el país que lidera el medallero general del Mundial. En la era profesional, el equipo se consagró campeón olímpico en 1992, 1996, 2000, 2008, 2012, 2016 y 2020. ",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuándo es el próximo Mundial de Baloncesto?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "La Copa Mundial de Baloncesto se llevará a cabo el viernes 25 de agosto de 2023 hasta el domingo 10 de septiembre y se celebrará conjuntamente en Filipinas, Japón e Indonesia.",
          },
        },
      ],
    };
    let title = "Apuestas para Baloncesto » Bono de $200.000 | Codere®";
    let desc =
      "En esta guía, te mostraremos cómo apostar en baloncesto con Codere, explicando tipos de apuestas, estadísticas clave y consejos para mejorar tus pronósticos.";

    let can = "https://www.codere.com.co/cuotas-deportivas/apuestas-baloncesto";

    CodereHelmet(title, desc, can, [json]);
  }, []);

  // Table of Contents  \\
  const table_of_contents_list = [
    {
      title: "¿Cómo funcionan las apuestas en Baloncesto de Codere?",
      id: "anchor-1",
    },
    {
      title: "Tipos de apuestas en Baloncesto",
      id: "anchor-2",
    },
    {
      title: "Estadísticas en Baloncesto",
      id: "anchor-3",
    },
    {
      title: "Consejos a la hora de apostar al baloncesto",
      id: "anchor-4",
    },
    {
      title: "Preguntas frecuentes sobre las apuestas de Baloncesto",
      id: "anchor-5",
    },
  ];

  return (
    <>
      {floating("Haz tu mejor canasta", false)}

      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${prefix}/${
            props.flag ? "M" : "D"
          }-balenco-header.webp)`,
          backgroundSize: "cover",
        }}
      ></div>
      <Container>
        <h1 className="text-center codere-green fw-normal mt-4">
          Aprende como apostar en las mejores ligas de baloncesto del mundo
        </h1>
        <p>
          El baloncesto es apasionante y las apuestas en este deporte están que
          arden. En esta guía, te mostraremos cómo apostar en baloncesto con
          Codere, explicando tipos de apuestas, estadísticas clave y consejos
          para mejorar tus pronósticos. Además, responderemos a tus preguntas
          más frecuentes. Sumérgete en el emocionante mundo en las ligas de
          baloncesto más destacadas del mundo.
        </p>
        <TableOfContents table={table_of_contents_list} />

        <h2 id={"anchor-1"} className="codere-green mt-3">
          ¿Cómo funcionan las apuestas en Baloncesto de Codere?
        </h2>
        <p>
          Dentro de las ofertas más destacadas encontramos Ganador del partido,
          Ganador del Cuarto, Ganador de la primera mitad y total de puntos. Sin
          embargo, también existen ofertas más avanzadas como el hándicap, las
          apuestas en directo, y las cuotas especiales.
        </p>

        <h3 className="codere-green mt-4 mb-2">Consejos Básicos</h3>

        <p>
          Lo más importante que debes tener en cuenta para realizar tus apuestas
          en el baloncesto es investigar los equipos y comparar las diferentes
          cuotas. No te dejes llevar por la emoción, ten un presupuesto definido
          y no apuestes más de lo que te permitas perder.
        </p>
        <h3 className="codere-green mt-4 mb-2">
          {" "}
          Rotaciones y descansos de los jugadores clave{" "}
        </h3>
        <p>
          En el deporte de la pelota naranja existen rotaciones ofensivas y
          defensivas que permiten definir los roles de los jugadores dentro del
          campo de juego, tanto para anotar como para evitar los despliegues
          amenazantes del rival. Para el caso de los tiempos muertos tenemos las
          variables de tiempos muertos, parciales y el final del compromiso.
        </p>
        <h3 className="codere-green mt-4 mb-2">Variaciones en las apuestas </h3>
        <p>
          En los partidos se pueden presentar variaciones para cada oferta como:
          ganador simple, ganador por hándicap, ganador del primer
          cuarto/mitad/cuarto; igual que encontraremos alguna variación para el
          total de los puntos, el margen de victoria y al primer/último equipo
          en anotar, máximo anotador, doble/triple-triple y más/menos puntos
          durante el partido (para los jugadores).
        </p>
        <p>
          Dentro de las demás variaciones que existen en el mercado podremos
          encontrar las apuestas a la mitad/cuarto, eventos específicos, en
          directo, combinadas, del sistema y también a largo plazo.
        </p>

        <h2 id={"anchor-2"} className="codere-green mt-3">
          Tipos de apuestas en Baloncesto
        </h2>

        <h3 className="codere-green mt-4 mb-2">Ganador del Partido</h3>
        <p>
          Con esta oferta puedes pronosticar qué equipo será el ganador del
          partido durante su tiempo reglamentario de 48 minutos.
        </p>
        <h3 className="codere-green mt-4 mb-2">Handicap</h3>
        <p>
          Gracias al hándicap podrás tener una ventaja o desventaja a un equipo
          en forma de puntos. Por ejemplo, si apuestas a un equipo con un
          hándicap de +5.5 puntos, ese equipo puede perder hasta por 5 puntos y
          tu apuesta aún será ganadora.
        </p>
        <h3 className="codere-green mt-4 mb-2">Más/Menos Puntos Totales</h3>
        <p>
          En esta oferta tú puedes indicar los puntos que se anotarán durante el
          juego independientemente del resultado de los equipos. Hay desde
          más/menos de 225.5, hasta más /menos 227.5.
        </p>

        <h3 className="codere-green mt-4 mb-2">Total de puntos Visitante</h3>
        <p>
          El total de puntos visitante, como su misma palabra lo indica, es la
          oportunidad para que tú como jugador puedas pronosticar cuántos puntos
          realizará el equipo visitante durante el compromiso,
          independientemente de la apuesta que realices. (aquí se tienen en
          cuenta desde el más/menos 110 hasta el más/menos 111 puntos).
        </p>

        <h2 className="codere-green mt-3">
          Conoce la opción de “Crea tu apuesta”
        </h2>
        <p>
          Esta es la nueva forma de realizar tus pronósticos deportivos; aquí
          puedes realizar múltiples combinaciones en tus partidos de baloncesto
          favoritos.
        </p>
        <p>Así de fácil puedes jugar con Crea tu apuesta:</p>
        <ol>
          <li>
            Elige el evento de baloncesto o NBA donde quieres hacer tu apuesta.
          </li>
          <li>Dale clic en la opción “Crea tu Apuesta”.</li>
          <li>Escoge los mercados que quieras.</li>
          <li>
            Cuando tengas todo listo, añade tus selecciones a tu boleto de
            apuestas.
          </li>
          <li>
            Indica la cantidad de dinero que desees apostar, confirma tu
            pronóstico ¡y listo!
          </li>
        </ol>

        <h2 id={"anchor-4"} className="codere-green mt-3">
          Consejos a la hora de apostar al baloncesto
        </h2>

        <p>
          Si quieres convertirte en uno de los cracks de los pronósticos
          deportivos con Codere, debes tener en cuenta que tu investigación de
          los equipos y el comparar las diferentes cuotas influye mucho en las
          apuestas. Así podrás analizar de una forma más completa cómo viene tu
          equipo favorito y las posibilidades.
        </p>

        <h3 id={"anchor-3"} className="codere-green mt-4 mb-2">
          Estadísticas en Baloncesto
        </h3>
        <p>
          Para el baloncesto existen estadísticas: porcentajes de tiros de
          campo, de tres puntos y de tiros libres. También se presentan
          mediciones en la valoración, eficiencia del jugador, la ratio de
          asistencias/balones perdidos y el net rating.
        </p>
        <p>
          Recuerda que, en nuestras políticas de juego responsable, se considera
          a los pronósticos deportivos una forma de entretenimiento y diversión;
          por ende, tener un presupuesto es crucial a la hora de apostar.
        </p>

        <h2 id={"anchor-5"} className="codere-green mt-3">
          Preguntas frecuentes
        </h2>
        <h3 className="codere-green mt-4 mb-2">
          ¿Qué es back-to-back en basket?
        </h3>
        <p>
          Este término es frecuente en el basket cuando se quiere hablar de
          partidos consecutivos o dos jugadas consecutivas para tomar la
          posesión del balón dentro de un mismo juego. Sus ejemplos se
          fundamentan de la siguiente forma: "Los Golden State Warriors tienen
          una serie de back-to-backs esta semana, lo que será una prueba difícil
          para su equipo." O "El equipo de LeBron James anotó dos canastas en
          back-to-back para tomar la delantera en el partido."
        </p>

        <h3 className="codere-green mt-4 mb-2">
          ¿Cómo hacer apuestas en baloncesto?{" "}
        </h3>
        <p>
          Para realizar pronósticos de baloncesto, puedes acceder a nuestra
          página web o descargar nuestra aplicación móvil. Una vez dentro,
          dirígete a la sección de "Deportes", busca la categoría de baloncesto
          o NBA y selecciona el evento de tu preferencia para divertirte
          haciendo tus apuestas.
        </p>

        <h3 className="codere-green mt-4 mb-2">
          ¿Qué tipos de apuestas de baloncesto existen?{" "}
        </h3>
        <p>
          Recuerda que tienes apuestas básicas o pronósticos más avanzados.
          Dentro de las básicas podremos encontrar ganador del partido, más o
          menos puntos, hándicap y par/impar.{" "}
        </p>
        <p>
          Para el caso de las cuotas avanzadas encontramos las apuestas por
          cuartos, mitades, jugador, en vivo, eventos específicos durante
          cualquier tramo del compromiso, así como también las cuotas
          especiales.{" "}
        </p>

        <LazyLoadImage
          className="lazyloadimg"
          src={`${prefix}/${props.flag ? "M" : "D"}-balenco-Basketball-3.webp`}
        />

        <Button
          href="https://m.codere.com.co/deportesCol/#/mso/RegistroCONewPage"
          className="mt-5 seo-reg-btn"
        >
          Regístrate
        </Button>
      </Container>
    </>
  );
};

export default ApuestasBaloncesto;
