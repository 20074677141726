import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SPEEDSIZE_PREFIX } from "../../App";
import React from "react";
export default function Navigations({ index, type }) {
  const prefix =
    type === 1
      ? `${SPEEDSIZE_PREFIX}https://www.codere.com.co/Colombia/images/casinoIcons/`
      : `${SPEEDSIZE_PREFIX}https://www.codere.com.co/Colombia/images/seo-sport/icons/`;

  const navs = [
    [
      // Type 0: Eventos
      {
        icon: "mundial",
        title: "Mundial",
        url: "/eventos-deportivos/apuestas-mundial-fifa",
      },
      {
        icon: "baloncesto",
        title: "Baloncesto",
        url: "/eventos-deportivos/apuestas-mundial-baloncesto",
      },
      {
        icon: "mlb",
        title: "MLB",
        url: "/eventos-deportivos/apuestas-juego-de-las-estrellas",
      },
      {
        icon: "superbowl",
        title: "Superbowl",
        url: "/eventos-deportivos/apuestas-superbowl",
      },
    ],
    [
      // Type 1: Casino
      { icon: "casino", title: "Casino", url: "/casino" },
      { icon: "en-vivo", title: "En Vivo", url: "/casino/casino-en-vivo" },
      { icon: "slots", title: "Slots", url: "/casino/slots" },
      { icon: "blackjack", title: "Blackjack", url: "/casino/blackjack" },
      { icon: "ruleta", title: "Ruleta", url: "/casino/ruleta" },
      { icon: "jackpots", title: "Jackpots", url: "/casino/jackpots" },
      { icon: "baccarat", title: "Baccarat", url: "/casino/baccarat" },
      { icon: "bingo", title: "Bingo", url: "/casino/video-bingo" },
    ],
    [
      // Type 2: Cuotas
      {
        icon: "baloncesto",
        title: "Baloncesto",
        url: "/cuotas-deportivas/apuestas-baloncesto",
      },
      {
        icon: "formula1",
        title: "Formula 1",
        url: "/cuotas-deportivas/apuestas-formula1",
      },
      {
        icon: "soccer",
        title: "Fútbol ",
        url: "/cuotas-deportivas/apuestas-futbol",
      },
      {
        icon: "cycling",
        title: "Ciclismo",
        url: "/cuotas-deportivas/apuestas-ciclismo",
      },
      {
        icon: "superbowl",
        title: "NFL",
        url: "/cuotas-deportivas/apuestas-nfl",
      },
      { icon: "boxeo", title: "UFC", url: "/cuotas-deportivas/apuestas-ufc" },
      {
        icon: "tenis",
        title: "Tenis",
        url: "/cuotas-deportivas/apuestas-tenis",
      },
    ],
  ];

  // const validType = type >= 0 && type < navs.length;
  // console.log(validType)
  return (
    <div className="e-nav d-flex align-items-center">
      {navs[type].map((n, k) => (
        <React.Fragment key={k}>
          <div
            className={`seo-nav-tab ${
              index === k + 1 ? `${type === 1 ? "c-" : ""}seo-nav-active` : ""
            }`}
          >
            <Link
              to={n.url}
              className={`text-white text-decoration-none ${
                index !== k + 1 ? "hoverable" : ""
              }`}
            >
              <LazyLoadImage
                src={prefix + n.icon + ".svg"}
                width={28}
                height={28}
              />
              <span className="seo-nav-title">{n.title}</span>
              <span className="underline" />
            </Link>
          </div>
          {k < navs[type].length - 1 && <div className="c-vr"></div>}
        </React.Fragment>
      ))}
    </div>
  );
}
