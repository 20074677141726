// import "@fontsource/roboto-condensed";
import { useEffect, useState } from "react";
import MySwiper from "./Parts/MySwiperV2";
import data from "../JSON/data_segmented.json";
import { Button, Col, Container, NavLink, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SportGames from "./Parts/SportGames";
import myStore from "../mobX/Store";
import { CodereHelmet } from "../App";
import { SPEEDSIZE_PREFIX } from "../App";
const InicioSegmented = (props) => {
  const GAME_PREFIX =
    "https://m.codere.com.co/deportesCol/#/casino/CasinoPage?lobby=Colombia&playgame=";

  const regis = "https://m.codere.com.co/deportesCol/#/mso/RegistroCONewPage";
  const [showTimer, setShowTimer] = useState(true);

  useEffect(() => {
    let json1 = {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "codere",
      alternateName: [
        "codere colombia",
        "codere.co",
        "codere.com.co",
        "codere co",
        "codere online",
        "codere sa",
        "codere s.a.",
      ],
      legalName: "Codere Online Colombia S.A.S.",
      url: "https://www.codere.com.co/",
      logo: "https://upload.wikimedia.org/wikipedia/commons/0/06/Codere_Logo.svg",
      foundingDate: "1984",
      contactPoint: {
        "@type": "ContactPoint",
        contactType: "customer support",
        telephone: "[01-8000-934313]",
        email: "apuestas@codere.com",
      },
      description:
        "La casa de apuestas deportivas y casino online #1 en Colombia. Juega en línea Blackjack, ruleta, slots y apuesta en tus deportes favoritos ⚽.",
      sameAs: [
        "https://www.facebook.com/CodereColombia",
        "https://twitter.com/CodereCO",
        "https://www.youtube.com/channel/UCT46v9eapBGC-3-vSHBlRvg",
        "https://www.instagram.com/codereco/",
        "https://apps.apple.com/co/app/codere-apuestas-deportivas-co/id1313823849",
        "https://play.google.com/store/apps/details?id=co.codere.apuestas",
        "https://es.wikipedia.org/wiki/Codere",
        "https://www.wikidata.org/wiki/Q2918415",
        "https://www.crunchbase.com/organization/codere",
        "https://play.google.com/store/apps/details?id=co.codere.casino",
        "https://www.google.com/search?kgmid=/g/121c515_",
      ],
    };
    let json2 = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Cuál es el mejor mercado para las apuestas deportivas?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "El mejor mercado para apostar o el más rentable es 1,2 lo que quiere decir que: gana local o gana visitante, ya sea en deportes como: Baloncesto, Béisbol o tenis, ya que en el caso del fútbol es diferente, porque está la opción 1X2, que incluye igualmente, gana local o gana visitante, pero además, el empate.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cuánto es lo mínimo en dinero para poder apostar?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "En Codere, la casa de apuestas en Colombia más bacana, el dinero mínimo que el usuario deberá tener para apostar son $1.000 pesos colombianos, con este monto, el usuario podrá apostar a cualquier deporte, ya sea baloncesto, tenis, béisbol, fútbol, entre otros que se pueden encontrar en la plataforma.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cómo hacer pronósticos de fútbol?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Existen diferentes tipos de herramientas para que el usuario pueda hacer pronósticos de fútbol, por ejemplo aplicaciones con datos y estadísticas, e incluso páginas web que registran el rendimiento de los equipos.",
          },
        },
        {
          "@type": "Question",
          name: "¿Cómo ganar en apuestas deportivas?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Las apuestas combinadas deportivas te pueden hacer ganar más dinero, ya que todas las selecciones que hagas se multiplican dependiendo de lo que apuestes, pero así como puedes ganar más, el nivel de complejidad aumenta, recuerda que si fallas una selección, fallarás la apuesta.",
          },
        },
      ],
    };
    let title = "Apuestas Deportivas y Casino » Bono de $200.000 | Codere®";
    let desc =
      "La casa de apuestas deportivas y casino online #1 en Colombia. Juega en línea Blackjack, ruleta, slots y apuesta en tus deportes favoritos ⚽.";
    let can = "https://www.codere.com.co/";

    CodereHelmet(title, desc, can, [json1, json2]);
  }, []);

  return (
    <>
      <div id="first-section">
        <MySwiper
          segmented_banners={`${SPEEDSIZE_PREFIX}${data}`}
          regis={regis}
          flag={props.flag}
          setShowTimer={setShowTimer}
        />
      </div>

      <Container>
        <SportGames />
        <div className="segmented-wrapper">
          <h2 className="codere-green align-center uppercase">
            Mejor juegos de casino
          </h2>
          <Row className="casino-row" style={{ margin: "-10px 0 12px 0" }}>
            {data.casino_games_list.map((game, k) => (
              <Col lg={2} md={4} xs={6} key={k}>
                <NavLink
                  rel="nofollow"
                  href={`${GAME_PREFIX}${game.supplier} ${game.name}`}
                >
                  <LazyLoadImage className="casino-game-img" src={game.image} />
                </NavLink>
              </Col>
            ))}
          </Row>
        </div>
        <div className="segmented-wrapper mt-4">
          <Row className={`${myStore.flag && "flex-column-reverse"}`}>
            <Col md={6} sm={12} className="segmented_col">
              <h3
                className={`codere-green ${
                  !props.flag ? "align-left" : "align-center"
                } text-uppercase`}
              >
                la casa de apuestas oficial más bacana
              </h3>
              <p className="grey">
                Somos Codere, líder en apuestas en línea en Colombia. Vive la
                emoción, estrategias y gana en un entorno seguro. Tenemos 40
                años de experiencia, ofreciendo confiabilidad y cumpliendo las
                normativas locales.
              </p>
              <Button
                href="https://m.codere.com.co/deportesCol/#/HomePage"
                className="segmented-button uppercase"
              >
                Aposta en vivo &gt;
              </Button>
            </Col>
            <Col md={6} sm={12}>
              <LazyLoadImage
                className="rounded-4 w-100 h-100"
                src={`${SPEEDSIZE_PREFIX}https://www.codere.com.co/assets/casa-oficial.webp`}
                width="413"
                height={myStore.flag ? "223" : "366"}
              />
            </Col>
          </Row>
        </div>
        <div className="segmented-wrapper mt-4">
          <Row
            className={`${
              myStore.flag ? "flex-column-reverse" : "flex-row-reverse"
            }`}
          >
            <Col md={6} sm={12} className="segmented_col">
              <h3
                className={`codere-green ${
                  !props.flag ? "align-left" : "align-center"
                } text-uppercase`}
              >
                el mejor casino en vivo
              </h3>
              <p className="grey">
                Aquí te ofrecemos la magia del casino en vivo desde tu
                computadora o smartphone. Tenemos emocionantes mesas de
                Blackjack, Baccarat, Poker, Ruleta y Slots. ¡Vive una
                experiencia de entretenimiento única!
              </p>
              <Button
                href="https://m.codere.com.co/deportesCol/#/casino/CasinoPage?lobby=Colombia"
                className="segmented-button uppercase"
              >
                Ver Mas &gt;
              </Button>
            </Col>
            <Col md={6} sm={12}>
              <LazyLoadImage
                className="rounded-4 w-100 h-100"
                src={`${SPEEDSIZE_PREFIX}https://www.codere.com.co/assets/casino-en-vivo.webp`}
                width="413"
                height={myStore.flag ? "223" : "366"}
              />
            </Col>
          </Row>
        </div>
        <div className="segmented-wrapper mt-4">
          <Row className={`${myStore.flag && "flex-column-reverse"}`}>
            <Col md={6} sm={12} className="segmented_col">
              <h3
                className={`codere-green ${
                  !props.flag ? "align-left" : "align-center"
                } text-uppercase`}
              >
                toda la emoción del deporte
              </h3>
              <p className="grey">
                Nuestra oferta tiene varios deportes para apostar, desde fútbol
                hasta los menos convencionales. Aquí cuentas con las opciones de
                Parlay y "Crea tu Apuesta". Juega y gana conociendo tus deportes
                favoritos.
              </p>
              <Button
                className="segmented-button text-uppercase"
                rel="nofollow"
                href="https://m.codere.com.co/deportesCol/#/CreaTuApuestaPage"
              >
                ver mas &gt;
              </Button>
            </Col>
            <Col md={6} sm={12}>
              <LazyLoadImage
                width="413"
                height={myStore.flag ? "223" : "366"}
                className="rounded-4 w-100 h-100"
                href="https://m.codere.com.co/deportesCol/#/CreaTuApuestaPage"
                src={`${SPEEDSIZE_PREFIX}https://www.codere.com.co/assets/deporte.webp`}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default InicioSegmented;
