import { Accordion, Col, Row, Table } from "react-bootstrap";
import React from "react";
import { NavLink } from "react-router-dom";

var questions = [
  "¿Cuáles son los tipos de apuestas deportivas?",
  "¿Cuánto es lo mínimo en dinero para poder apostar?",
  "¿Cómo hacer pronósticos de fútbol?",
  "¿Cómo ganar en apuestas deportivas?",
];
var answers = [
  "El mejor mercado para apostar o el más rentable es el 1,2 lo que quiere decir que gana local o gana visitante, esto sucede en deportes como Baloncesto, Béisbol o Tenis. Para el caso del fútbol es diferente, existe la opción 1X2, que incluye igualmente, gana local o gana visitante y el empate.",
  "En Codere, la casa de apuestas en Colombia más bacana, el dinero mínimo que deberás tener para apostar es de $1.000 pesos colombianos, con este monto, podrás apostar a cualquier deporte, ya sea baloncesto, tenis, béisbol, fútbol, entre otros que se pueden encontrar en la plataforma.",
  "Existen diferentes tipos de herramientas para que puedas hacer pronósticos de fútbol, por ejemplo, aplicaciones con datos y estadísticas, e incluso páginas web que registran el rendimiento de los equipos.",
  "Las apuestas combinadas deportivas te pueden hacer ganar más dinero, ya que todas las selecciones que hagas multiplican la posibilidad de ganar dependiendo de lo que apuestes, pero, así como puedes ganar más, el nivel de complejidad aumenta. Recuerda que, si fallas una selección, fallarás la apuesta.",
];
function Content() {
  return (
    <div className="content-container inicioPageTextData">
      <Row>
        <Col md={4} xs={12}>
          <h1>La Casa Líder de Apuestas Deportivas en Colombia </h1>
          <p>
            Bienvenido a Codere, la casa de apuestas en línea donde la emoción y
            la seguridad se unen. Como una de las mejores plataformas de
            apuestas en Colombia, te ofrecemos opciones únicas para que
            disfrutes de apuestas deportivas, desde partidos de la Liga
            Colombiana hasta eventos internacionales como la Copa Libertadores.
          </p>

          <h2>Seguridad y Confianza en Apuestas en Línea </h2>
          <p>
            Tu tranquilidad es nuestra prioridad. Codere opera bajo la
            supervisión de Coljuegos, cumpliendo con todos los estándares
            legales y de seguridad en Colombia. Aquí te ofrecemos un entorno
            confiable, protegido por tecnología de última generación, para que
            realices tus apuestas en línea con total confianza. Además, nuestros
            métodos de pago locales como PSE y opciones físicas facilitan tus
            transacciones.
          </p>

          <h3>Variedad de Opciones en Apuestas Deportivas </h3>
          <p>
            En Codere, tendrás una amplia variedad de opciones para que elijas
            la apuesta que mejor se adapte a tus intereses y habilidades. Ya sea
            que estés buscando jugar en vivo o explorando mercados avanzados
            como hándicap asiático, aquí encontrarás todas las herramientas y
            mercados necesarios para disfrutar al máximo del deporte:
          </p>
          <ul>
            <li>
              <u>Apuestas 1X2:</u> La opción clásica donde puedes apostar por el
              triunfo de un equipo (1), el empate (X) o la victoria del otro
              equipo (2). Por ejemplo, en un partido entre Millonarios y
              Nacional, puedes elegir el resultado que más creas probable.
            </li>
          </ul>
        </Col>

        <Col md={4} xs={12}>
          <ul>
            <li>
              <u>Doble oportunidad:</u> Amplía tus opciones combinando
              resultados. Por ejemplo, en un juego entre Independiente Santa Fe
              y América de Cali, puedes apostar a que Santa Fe gana o empata
              (1X), o que cualquiera de los dos equipos gane (12).
            </li>
            <li>
              <u>Marcador exacto:</u> Predice el resultado específico del
              partido. Por ejemplo, en un enfrentamiento entre Colombia y
              Brasil, puedes apostar a un marcador exacto de 2-1 a favor de
              Colombia.
            </li>
            <li>
              <u>Hándicap y hándicap asiático:</u> Aumenta el desafío con estas
              opciones. Por ejemplo, si Atlético Nacional tiene un hándicap de
              -1, deben ganar por al menos dos goles para que tu apuesta sea
              ganadora. En el hándicap asiático, si el resultado cae exactamente
              en el margen seleccionado, la apuesta se anula y recuperas tu
              dinero.
            </li>
            <li>
              <u>Primer equipo en marcar:</u> Una apuesta directa y emocionante
              para predecir quién anotará el primer gol en un partido.
            </li>
            <li>
              <u>Apuestas combinadas:</u> Eleva la emoción combinando diferentes
              mercados. Por ejemplo, en un partido de la Liga Colombiana, puedes
              apostar a que habrá empate y que ambos equipos anotarán (X + ambos
              anotan).
            </li>
            <li>
              <u>Marcan ambos equipos:</u> Perfecto para partidos con equipos
              ofensivos. Por ejemplo, en un enfrentamiento entre Junior y
              América de Cali, puedes apostar a que ambos equipos anotarán al
              menos un gol durante el partido.
            </li>
          </ul>
          <p>
            Explora todas las opciones y haz que tus apuestas deportivas en
            Colombia sean más emocionantes y rentables con Codere.
          </p>
          <h2>Aprovecha tu Bono de Bienvenida</h2>
          <p>
            ¡Es tu momento de ganar! Regístrate en Codere.com.co y disfruta de
            un bono de bienvenida exclusivo para comenzar tu aventura en las
            apuestas deportivas y el casino online. Descubre por qué somos la
            elección favorita de los colombianos. Vive la pasión del juego
            responsable y seguro con Codere.
          </p>
        </Col>
        <Col md={4} xs={12}>
          <aside>
            <h2>Más que simples apuestas: Vive el Casino Online de Colombia</h2>
            <p>
              Codere no solo es líder en apuestas deportivas en línea en
              Colombia; también redefine la experiencia del casino online en el
              país con una oferta de alta calidad y entretenimiento inigualable:
            </p>
            <h3>¿Qué te espera al Jugar Online? </h3>
            <p>
              Disfruta de una experiencia de juego diseñada para emocionarte y
              entretenerte al máximo:
            </p>
            <ul className="seo-text-links-container">
              <li>
                <NavLink
                  to="/casino/ruleta"
                  className="underline text-blue-600"
                >
                  Ruleta en vivo
                </NavLink>
                : Juega con crupieres reales desde cualquier lugar y siente la
                emoción auténtica de un casino físico, todo desde la comodidad
                de tu hogar.
              </li>
              <li>
                <NavLink to="/casino/slots" className="underline text-blue-600">
                  Tragamonedas
                </NavLink>
                : Experimenta la suerte en los slots más populares y gana
                grandes premios con gráficos y sonidos cautivadores que te
                sumergen en la acción.
              </li>
              <li>
                <NavLink
                  to="/casino/blackjack"
                  className="underline text-blue-600"
                >
                  Blackjack Online
                </NavLink>
                : Mejora tus habilidades y enfrenta la banca o a otros jugadores
                en tiempo real. Prueba tus estrategias y disfruta de un ambiente
                de juego auténtico y desafiante.
              </li>
              <li>
                <NavLink to="/casino">Casino Online</NavLink>: Calidad y
                seguridad que solo una marca líder puede proporcionar. Disfruta
                de gráficos de última generación y una experiencia inmersiva que
                te transporta al corazón del casino.
              </li>
              <li>
                <NavLink
                  to="/casino/casino-en-vivo"
                  className="underline text-blue-600"
                >
                  Casino en Vivo
                </NavLink>
                : Vive una experiencia inmersiva con crupieres en vivo, donde
                cada giro de la ruleta o mano de blackjack es manejada por un
                profesional en tiempo real, ofreciendo una sensación de casino
                físico directamente desde tu dispositivo.
              </li>
            </ul>

            <p>
              Prepárate para descubrir un universo de entretenimiento
              inigualable, diseñado para que vivas cada jugada al máximo. ¡La
              diversión comienza aquí!
            </p>
          </aside>
        </Col>
      </Row>

      <Table
        striped
        bordered
        hover
        variant="dark"
        className="responsive-table mt-4"
      >
        <tbody>
          <tr>
            <td>🏆 Inicio de operaciones</td>
            <td>1984</td>
          </tr>
          <tr>
            <td>⚽ Apuestas Deportivas</td>
            <td>
              Apuestas deportivas futbol en vivo, Apuestas Champions League,
              Apuestas NBA y más
            </td>
          </tr>
          <tr>
            <td>🎲 Licencia</td>
            <td>C1901 15 de noviembre de 2022 al 10 noviembre de 2025</td>
          </tr>
          <tr>
            <td>✔️ Moneda</td>
            <td>COP</td>
          </tr>
          <tr>
            <td>💰 Depósito mínimo</td>
            <td>$8.000 COP</td>
          </tr>
          <tr>
            <td>💸 Límites de pago</td>
            <td>desde $50.000 pesos hasta $1.500.000 pesos por transacción.</td>
          </tr>
          <tr>
            <td>💳 Formas de Pago</td>
            <td>
              Visa, MasterCard, PSE, PAyU, tpaga, efecty, Dimonex, Punto Red,
              Daviplata, Via Baloto
            </td>
          </tr>
        </tbody>
      </Table>

      <section id="acc-section" className="mt-5">
        <h2 className="bottomContentTitle">
          Preguntas frecuentes sobre apuestas deportivas en Codere
        </h2>

        <Accordion
          className="content-accordion"
          defaultActiveKey={["0"]}
          alwaysOpen
        >
          {answers.map((answer, index) => {
            return (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header as="h3">{questions[index]}</Accordion.Header>
                <Accordion.Body>
                  <p>{answer}</p>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>

        <h3 className="bottomContentSpecialTitle mt-4">
          ¡ANÍMATE Y DESCUBRE TODAS LAS VENTAJAS QUE TE OFRECE CODERE!
        </h3>
        <div className="fw-bold text-center fs-2">
          La emoción del deporte y del casino al alcance de tu mano
        </div>
      </section>
    </div>
  );
}

export default Content;
