import { SPEEDSIZE_PREFIX } from "../../App";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function CasinoGameItem({ game }) {
  const image = `${game.name.replaceAll(" ", "")}_Square.jpg`;

  const playURL =
    "https://www.codere.com.co/Colombia/images/casinoIcons/playHoverLogo.svg";
  const game_prefix =
    "https://m.codere.com.co/deportesCol/#/casino/CasinoPage?lobby=Colombia&playgame=";

  return (
    <div
      onClick={() => window.open(`${game_prefix}${game.name}`, "_self")}
      className="cas-game-wrapper cursor-pointer"
    >
      <LazyLoadImage
        className="casino-game-img shining"
        src={`${SPEEDSIZE_PREFIX}https://www.codere.com.co/lobby_tiles/${image}`}
      />
      <div className="hvr">
        <p>{game.name}</p>
        <LazyLoadImage src={`${SPEEDSIZE_PREFIX}${playURL}`} />
      </div>
    </div>
  );
}
