import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import React, { useEffect } from "react";
import { CodereHelmet, SPEEDSIZE_PREFIX } from "../../../App";
import EventCard from "../../Parts/EventCard";
import { floating } from "../helpers";

const EventosDeportivos = (props) => {
  const prefix = `${SPEEDSIZE_PREFIX}https://www.codere.com.co/SEOpagesImg/EventosDeportivos/`;

  useEffect(() => {
    let json = {};
    let title = "Los Eventos Deportivos más vistos en el Mundo | Codere®";
    let desc =
      "Conoce todo sobre los mejores eventos deportivos 📅. Prueba tu suerte y vive la experiencia Codere en los momentos más apasionantes.";

    let can = "https://www.codere.com.co/eventos-deportivos";

    CodereHelmet(title, desc, can, [json]);
  }, []);

  const data = {
    top_games: [
      {
        img: "banner-eventos-mundial",
        url: "/eventos-deportivos/apuestas-mundial-fifa",
        h2: "Apuestas para el Mundial",
        game: "Mundial",
      },
      {
        img: "banner-eventos-basket",
        url: "/eventos-deportivos/apuestas-mundial-baloncesto",
        h2: "Apuestas Mundial de Baloncesto",
        game: "Baloncesto",
      },
      {
        img: "banner-eventos-mlb",
        url: "/eventos-deportivos/apuestas-juego-de-las-estrellas",
        h2: "Apuestas Juego de las Estrellas MLB",
        game: "MLB",
      },
      {
        img: "banner-eventos-superbowl",
        url: "/eventos-deportivos/apuestas-superbowl",
        h2: "Apuestas para el Superbowl",
        game: "Superbowl",
      },
    ],
  };

  return (
    <>
      <div id="eventos">
        {floating("Vive la Experiencia", false)}
        <div
          className="top-bg-seo"
          style={{
            backgroundImage: `url(${prefix}general-${
              !props.flag ? "desktop" : "mobile"
            }.jpg)`,
            backgroundSize: "cover",
          }}
        ></div>

        <h1 style={{ color: "#fff", textAlign: "center", padding: "1.5rem" }}>
          Los mejores Eventos Deportivos estan en Codere
        </h1>

        <Container className="eventos-container">
          <div className="row">
            {data.top_games.map((g, i) => (
              <EventCard key={i} g={g} prefix={prefix} flag={props.flag} />
            ))}
          </div>
        </Container>
      </div>
    </>
  );
};
<Outlet />;
export default EventosDeportivos;
