import gameCSS from "../../CSS/games.module.css";
import { NavLink } from "react-bootstrap";
import { Bounce } from "react-awesome-reveal";
import { Arrowrightw } from "../../icons";
import { replaceSpanishCharacters } from "./Games";
import { SPEEDSIZE_PREFIX } from "../../App";
function PortraitGame({ game }) {
  const gamePrefix = `https://m.codere.com.co/deportesCol/#/casino/SlotsPage?lobby=Colombia&playgame=`;
  const imgPrefix = `${SPEEDSIZE_PREFIX}https://www.codere.com.co/Colombia/images/inisio`;

  return (
    <NavLink
      href={`${gamePrefix}${replaceSpanishCharacters(game.name)}`}
      rel="nofollow"
    >
      <div className={gameCSS.portrait_card}>
        <div className={gameCSS.portrait_box}>
          <div className={gameCSS.game}>
            <div className={game.id === 1 ? gameCSS.shiny : ""}>
              <img
                src={`${imgPrefix}/${replaceSpanishCharacters(game.name)
                  .toLowerCase()
                  .replaceAll(/ /g, "-")}.webp`}
                alt={game.name}
              />
            </div>
          </div>

          <div className={`${gameCSS.logo} bottom-logo-${game.id + 1}`}>
            <Bounce duration={1500} right>
              <img
                src={`${imgPrefix}/${replaceSpanishCharacters(game.name)
                  .toLowerCase()
                  .replaceAll(/ /g, "-")}-logo.webp`}
                alt={`${game.name}-logo`}
              />
            </Bounce>
          </div>
        </div>
        <div className={gameCSS.portrait_content}>
          <span
            className={`${gameCSS.regis} d-flex m-auto justify-content-center`}
          >
            Juega Ahora
            <div id={gameCSS.game_svg}>
              <Arrowrightw />
            </div>
          </span>
        </div>
        <div className={gameCSS.portrait_game_title}>
          <h3>{game.name.split(" ").slice(1).join(" ")}</h3>
        </div>
      </div>
    </NavLink>
  );
}

export default PortraitGame;
