import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { isMobile } from "react-device-detect";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import StickyFooter from "./Components/Parts/StickyFooter";
import MyRouting from "./routes/MyRouting";
import { Outlet } from "react-router-dom";
import { observer } from "mobx-react";
import myStore from "./mobX/Store";
export const SPEEDSIZE_PREFIX = `https://ecdn.speedsize.com/a8158cbe-3d61-41ee-bbfa-6e8051dcc86d/`;

export function CodereHelmet(title, description, canonical, jsons) {
  // Update the document title
  document.title = title;

  // Handle meta description
  let metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", description);
  } else {
    metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content = description;
    document.head.appendChild(metaDescription);
  }

  // Handle canonical link
  let canonicalLink = document.querySelector('link[rel="canonical"]');
  if (canonicalLink) {
    canonicalLink.setAttribute("href", canonical);
  } else {
    canonicalLink = document.createElement("link");
    canonicalLink.rel = "canonical";
    canonicalLink.href = canonical;
    document.head.appendChild(canonicalLink);
  }

  // Remove existing JSON-LD structured data
  const jsonLD = document.querySelectorAll(
    'script[type="application/ld+json"]'
  );
  jsonLD.forEach((json) => {
    json.remove();
  });

  // Add new JSON-LD structured data
  jsons.forEach((json) => {
    const tempJson = document.createElement("script");
    tempJson.type = "application/ld+json";
    tempJson.textContent = JSON.stringify(json);
    document.head.appendChild(tempJson);
  });

  // Remove existing title and meta description elements (excluding JSON-LD)
  const headChildren = document.head.children;
  for (let i = headChildren.length - 1; i >= 0; i--) {
    const child = headChildren[i];
    if (
      child.tagName === "TITLE" ||
      (child.tagName === "META" && child.name === "description")
    ) {
      child.remove();
    }
  }

  // Place new title and meta description immediately after <head> tag
  const head = document.head;
  const firstChild = head.firstChild;

  let newTitle = document.createElement("title");
  newTitle.textContent = title;
  head.insertBefore(newTitle, firstChild);

  let newMetaDescription = document.createElement("meta");
  newMetaDescription.name = "description";
  newMetaDescription.content = description;
  head.insertBefore(newMetaDescription, head.children[1]);
}

const App = observer(() => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (windowSize.width <= 768 || isMobile) {
      myStore.updateFlag(true);
    } else myStore.updateFlag(false);
  }, [windowSize]);

  useEffect(() => {
    myStore.updateFlag(isMobile);
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="App">
      <Header />
      <MyRouting />
      <Outlet />
      <CookieConsent
        cookieName="codere_cookie"
        expires={365}
        buttonText="- Aceptar -"
        buttonClasses="cookie-btn"
        overlay={true}
        cookieValue={"accepted"}
        extraCookieOptions={{ domain: ".codere.com.co" }}
        overlayClasses="cookie-overlay"
        containerClasses="cookie-container"
        contentClasses="cookie-content"
      >
        <h3>Uso Cookies</h3>
        Utilizamos cookies propias y de terceros para mejorar tu accesibilidad,
        personalizar, analizar tu navegación, así como para mostrar anuncios
        basados en tus intereses. Si sigues navegando, consideramos que aceptas
        su uso. Puedes obtener más información en nuestra{" "}
        <a
          rel="nofollow"
          href="https://www.codere.com.co/Paginas/Pol%C3%ADtica-de-cookies.aspx"
        >
          política de cookies
        </a>
        .
      </CookieConsent>
      {myStore.flag && <StickyFooter />}
      <Footer />
    </div>
  );
});

export default App;
