import React from "react";
import { Card, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const EventCard = ({ g, prefix, flag }) => {
  return (
    <Col md={4} xs={6}>
      <Card className="eventos_card">
        <Link style={{ textDecoration: "unset" }} to={g.url}>
          <Card.Header as={"h2"}>{!flag ? g.h2 : g.game}</Card.Header>
        </Link>
        <Card.Body>
          <LazyLoadImage src={prefix + g.img + ".webp"} />
        </Card.Body>
        <Card.Footer
          className={`ps-${flag ? "1" : "3"} pe-${flag ? "1" : "3"}`}
        >
          <Link rel="nofollow" to={g.url}>
            <Button className="w-100 fw-bold" as="span">
              VER INFORMACIÓN
            </Button>
          </Link>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default EventCard;
