import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { CodereHelmet } from "../../../App";
import EventCard from "../../Parts/EventCard";
import { floating } from "../helpers";
import { SPEEDSIZE_PREFIX } from "../../../App";
const CuotasDeportivos = (props) => {
  const prefix =
    `${SPEEDSIZE_PREFIX}https://www.codere.com.co/Colombia/images/seo-sport/cuotas-deportivas`;

  useEffect(() => {
    let json = {};
    let title = "Los Eventos Deportivos más vistos en el Mundo | Codere®";
    let desc =
      "Conoce todo sobre los mejores eventos deportivos 📅. Prueba tu suerte y vive la experiencia Codere en los momentos más apasionantes.";

    let can = "https://www.codere.com.co/cuotas-deportivas";

    CodereHelmet(title, desc, can, [json]);
  }, []);

  const data = {
    top_games: [
      {
        img: "/nfl-superbowl-promo",
        url: "/cuotas-deportivas/apuestas-nfl",
        h2: "Apuestas NFL",
        game: "NFL",
      },
      {
        img: "/ciclismo-promo",
        url: "/cuotas-deportivas/apuestas-ciclismo",
        h2: "Ciclismo",
        game: "Ciclismo",
      },
      {
        img: "/futbol-promo",
        url: "/cuotas-deportivas/apuestas-futbol",
        h2: "Fútbol",
        game: "Cuotas Fútbol ",
      },

      {
        img: "/f1-promo",
        url: "/cuotas-deportivas/apuestas-formula1",
        h2: "Formula 1",
        game: "Formula 1",
      },
      {
        img: "/balenco-promo",
        url: "/cuotas-deportivas/apuestas-baloncesto",
        h2: "Baloncesto",
        game: "Baloncesto",
      },
      {
        img: "/ufc-promo",
        url: "/cuotas-deportivas/apuestas-ufc",
        h2: "UFC",
        game: "UFC",
      },
      {
        img: "/tenis-promo",
        url: "/cuotas-deportivas/apuestas-tenis",
        h2: "Tenis",
        game: "Tenis",
      },
    ],
  };

  return (
    <>
      <div id="eventos">
        {floating("Vive la Experiencia", false)}
        <div
          className="top-bg-seo"
          style={{
            backgroundImage: `url(${prefix}/general-${
              !props.flag ? "desktop" : "mobile"
            }.webp)`,
            backgroundSize: "cover",
          }}
        ></div>

        <h1 style={{ color: "#fff", textAlign: "center", padding: "1.5rem" }}>
          Las mejores Cuotas Deportivas están en Codere
        </h1>
        <Container className="eventos-container">
          <Row>
            {data.top_games.map((g, i) => (
              <EventCard key={i} g={g} prefix={prefix} flag={props.flag} />
            ))}
          </Row>
        </Container>
      </div>
      <Outlet />
    </>
  );
};

export default CuotasDeportivos;
